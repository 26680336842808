@import "vendor/animate/animate.min";

// Bootstrap variables
@import "vendor/bootstrap/scss/functions";
@import "vendor/bootstrap/scss/variables";

// Theme variables
@import "variables";

// User variables
@import "user-variables";

// Bootstrap core
@import "vendor/bootstrap/scss/bootstrap";

// Extended bootstrap utilities
@import "custom/utilities";
@import "vendor/bootstrap/scss/utilities/api";

// Extended bootstrap components
@import "custom/dropdowns";
@import "custom/list-group";
@import "custom/nav";
@import "custom/navbar";
@import "custom/reboot";
@import "custom/type";

// Theme components
@import "components/general";

@import "components/overlay";
@import "components/utilities";

// In this scss you can write your scss/css
@import "user";
