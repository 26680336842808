//
// nav.scss
// Extended from Bootstrap
//

//
// Additional style for theme
//

// Pills
.nav-pills {
  .nav-link {
    cursor: pointer;
    margin-bottom: 15px;
    color: $nav-pills-link-color;
    padding: 0.5rem 1rem;
  }
}

// nav
.nav.flex-column:not(.nav-pills) .nav-link {
  padding-left: 0;
  padding-top: 0.30rem;
  padding-bottom: 0.30rem;
}
