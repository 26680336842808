//
// general.scss
// Theme general elements and helper styling


// animate.css root animate-delay change from 1s to 0.5s to make animation slower
:root {
  --animate-delay: 0.5s;
}

// GLightbox controls theme
.plyr__control--overlaid, .plyr--video .plyr__control.plyr__tab-focus, .plyr--video .plyr__control:hover, .plyr--video .plyr__control[aria-expanded=true]{
  background: $primary;
}
.plyr--full-ui input[type=range]{
  color: $primary;
}

/* rtl:raw:
.glightbox-clean .gclose, .glightbox-modern .gclose{
  right: auto !important;
  left: 20px;
}
*/

// image trail effects
.content__img {
  position: absolute;
  top: 0;
  left: 0 #{"/* rtl:ignore */"};
  opacity: 0;
}

// bootstrap icon line height override
.bi{
  line-height: 0;
}

// ityped cursor blink
.ityped-cursor {
  color: $dark;
  opacity: 1;
  animation: cursorblink 0.3s infinite;
  animation-direction: alternate;
}

@keyframes cursorblink {
  100% {
    opacity: 0;
  }
}

@-webkit-keyframes cursorblink {
  100% {
    opacity: 0;
  }
}

@-moz-keyframes cursorblink {
  100% {
    opacity: 0;
  }
}

@-webkit-keyframes scrolldown {
  0% {
    background-position: 0 -60px
  }

  75% {
    background-position: 0 0
  }

  to {
    background-position: 0 60px
  }

}

@keyframes scrolldown {
  0% {
    background-position: 0 -60px
  }

  75% {
    background-position: 0 0
  }

  to {
    background-position: 0 60px
  }

}

// Back to top
.back-top {
  cursor: pointer;
  position: fixed !important;
  bottom: 0;
  right: 0;
  display: block;
  z-index: 99;
  visibility: hidden;
  opacity: 0;
  transform: translateY(50%);
  transition: $transition-base;
  background: $primary;
  color: $white;
  padding: 20px 0 15px 0;
  width: 40px;
  position: absolute;
  color: $white;

  &.back-top-show {
    visibility: visible;
    opacity: 1;
    transform: translateY(0%);
    transition: all 1s ease-in-out;
  }

  .scoll-text {
    writing-mode: vertical-rl;
    padding-left: 8px;
  }

  .scroll-line {
    width: 1px;
    height: 60px;
    overflow: hidden;
    margin: 0 0 10px 20px;

    &:after {
      content: "";
      width: 100%;
      height: 100%;
      display: block;
      background: linear-gradient(180deg, $white 50%, rgba(21, 175, 90, 0) 0);
      background-position: 0 -60px;
      background-size: 100% 200%;
      -webkit-animation: backtop 5s cubic-bezier(0.76, 0, 0.3, 1) infinite forwards;
      animation: backtop 5s cubic-bezier(0.76, 0, 0.3, 1) infinite forwards;
    }

  }

}

@-webkit-keyframes backtop {
  from {
    background-position: 0 -60px
  }

  75% {
    background-position: 0 0
  }

  0% {
    background-position: 0 60px
  }

}

@keyframes backtop {
  from {
    background-position: 0 -60px
  }

  75% {
    background-position: 0 0
  }

  0% {
    background-position: 0 60px
  }

}

// To flip element in RTL
/* rtl:raw:
  .rtl-flip {
    transform: scaleX(-1);
  }
  .rtl-flip.bi:before{
    transform: inherit;
  }
*/

//
// Animations
//

// col md full right
@include media-breakpoint-up(md) {
  .col-md-full-right {
    position: absolute;
    width: 1000%;
    max-width: 50%;
    height: 100%;
    right: 0;
    bottom: 0;
  }
}

//  Footer fixed
@media (min-width:768px) {
  footer.footer-sticky {
    position: fixed !important;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: -1 !important;
  }
}

// pre loader
.preloader {
  background-color: $white;
  position: fixed;
  z-index: 1190;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;

  .preloader-item {
    position: absolute;
    top: 50%;
    left: 50%;
    text-align: center;
    transform: translate(-50%, -50%);
  }
}
